// Build information, automatically generated by `ng-info`
const build = {
    version: "2.19.0",
    timestamp: "Thu Oct 10 2024 16:43:42 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "develop",
        hash: "7b1aaa",
        fullHash: "7b1aaad23b351ec74e2ff28849dd3cf11c0d0ef3"
    }
};

export default build;