import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes, ExtraOptions} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import './rxjs-extensions';
import 'chart.js/dist/Chart.min.js';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SearchComponent } from './pages/legacy/search';
import {TableModule} from 'primeng/table';
import {InputMaskModule} from 'primeng/inputmask';
import {GMapModule} from 'primeng/gmap';
import { MerchantComponent } from './pages/merchant/merchant.component';
import {MerchantService} from './services/offers/merchant.service';
import {BadgeComponent} from './components/badge/component/badge';
import { PhoneComponent } from './components/phone/phone.component';
import { LoanDetailComponent } from './components/loans/loan-detail/loan-detail.component';
import { SummaryComponent } from './pages/legacy/summary/summary.component';
import {LoanService} from './services/offers/loan.service';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { PhonePipe } from './pipes/PhonePipe';
import { LoanFunnelComponent } from './components/loans/loan-funnel/loan-funnel.component';
import { LoanFeedComponent } from './pages/legacy/loan-feed/loan-feed.component';
import { MerchantDetailComponent } from './components/merchant-detail/merchant-detail.component';
import { SherlockListComponent } from './components/sherlock-table/sherlock-list.component';
import {SherlockService} from './services/sherlock.service';
import { SherlockDetailComponent } from './pages/sherlock/sherlock-detail/sherlock-detail.component';
import { AddressBasicComponent } from './components/address-basic/address-basic.component';
import {SSNPipe} from './pipes/SSNPipe';
import {AccessGuard} from './services/access-guard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import { PageUserDetailComponent } from './pages/page-user-detail/page-user-detail.component';
import { StCardViewComponent } from './components/st-card-view/st-card-view.component';
import { StCardViewRowComponent } from './components/st-card-view-row/st-card-view-row.component';
import {FundsService} from './services/offers/funds.service';
import { LoanListComponent } from './components/loans/loan-list/loan-list.component';
import { FormCreateUserComponent } from './pages/legacy/form-create-user/form-create-user.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageLoanApplicationComponent } from './pages/offers/page-loan-application/page-loan-application.component';
import { PageReportsComponent } from './pages/legacy/page-reports/page-reports.component';
import {ReportService} from './services/report.service';
import {CurrencyPipe} from '@angular/common';
import { OfferListComponent } from './components/offer-list/offer-list.component';
import { ActivityFeedComponent } from './pages/activity-feed/activity-feed.component';
import {ActivityService} from './services/offers/activity.service';
import {AuthService} from './services/auth.service';
import { PageActivitiesComponent } from './pages/offers/page-activities/page-activities.component';
import { PageSearchTransactionsComponent } from './pages/hapi/page-search-transactions/page-search-transactions.component';
import { FeedItemComponent } from './components/feed-item/feed-item.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PagePaymentsComponent } from './pages/offers/page-payments/page-payments.component';
import {PaymentsService} from './services/payments.service';
import {RewardsService} from './services/offers/rewards.service';
import { PageRewardsComponent } from './pages/offers/page-rewards/page-rewards.component';
import { FormRewardCampaignComponent } from './pages/offers/form-reward-campaign/form-reward-campaign.component';
import { PageCreateRewardTransactionComponent } from './pages/offers/page-create-reward-transaction/page-create-reward-transaction.component';
import { FormKycComponent } from './pages/sherlock/form-kyc/form-kyc.component';
import { PageSearchOffersUsersComponent } from './pages/offers/page-search-offers-users/page-search-offers-users.component';
import {InterceptorService} from './services/interceptor.service';
import {HapiService} from './services/hapi.service';
import {ConsumersApiService} from './services/consumers-api.service';
import { ConsumerCardComponent } from './components/user-detail/consumer-card/consumer-card.component';
import { KycCardComponent } from './components/user-detail/kyc-card/kyc-card.component';
import { PageConsumerSearchComponent } from './pages/consumers/page-consumer-search/page-consumer-search.component';
import { WalletOvcardCardComponent } from './components/user-detail/wallet-ovcard-card/wallet-ovcard-card.component';
import { AddressesCardComponent } from './components/user-detail/addresses-card/addresses-card.component';
import { ActivitiesTableComponent } from './components/activities-table/activities-table.component';
import { FundsCardComponent } from './components/user-detail/funds-card/funds-card.component';
import { PageSearchKycComponent } from './pages/sherlock/page-search-kyc/page-search-kyc.component';
import { OffersUserCardComponent } from './components/user-detail/offers-user-card/offers-user-card.component';
import {OffersService} from './services/offers/offers-api.service';
import { PageDeploymentsComponent } from './pages/page-deployments/page-deployments.component';
import {WalletService} from './services/wallet.service';
import { PageFirmwareComponent } from './pages/wallet/page-firmware/page-firmware.component';
import { WalletMobilesCardComponent } from './components/user-detail/wallet-mobiles-card/wallet-mobiles-card.component';
import { WalletValetsCardComponent } from './components/user-detail/wallet-valets-card/wallet-valets-card.component';
import { WalletTokensCardComponent } from './components/user-detail/wallet-tokens-card/wallet-tokens-card.component';
import { WalletEventsTableComponent } from './components/wallet-events-table/wallet-events-table.component';
import { TransactionsTableComponent } from './components/transactions-table/transactions-table.component';
import {TokenizedTransactionsTableComponent} from './components/tokenized-transactions-table/tokenized-transactions-table.component';
import { FormFirmwareComponent } from './components/form-firmware/form-firmware.component';
import { TransactionsCofTableComponent } from './components/transactions-cof-table/transactions-cof-table.component';
import { PageCofTransactionsComponent } from './pages/payments/page-cof-transactions/page-cof-transactions.component';
import { PageWalletBinsComponent } from './pages/wallet/page-wallet-bins/page-wallet-bins.component';
import { FormBinComponent } from './components/form-bin/form-bin.component';
import { PageValetWhitelistComponent } from './pages/wallet/page-valet-whitelist/page-valet-whitelist.component';
import { TopupsTableComponent } from './components/topups-table/topups-table.component';
import { FormBulkBinComponent } from './components/form-bulk-bin/form-bulk-bin.component';
import { WalletCardsCardComponent } from './components/user-detail/wallet-cards-card/wallet-cards-card.component';
import { PagedCardComponent } from './components/user-detail/paged-card/paged-card.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TabViewModule} from 'primeng/tabview';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToastModule} from 'primeng/toast';
import {MenuModule} from 'primeng/menu';
import {MegaMenuModule} from 'primeng/megamenu';
import {PanelModule} from 'primeng/panel';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TooltipModule} from 'primeng/tooltip';
import {PaginatorModule} from 'primeng/paginator';
import {RadioButtonModule} from 'primeng/radiobutton';
import { WalletBanksCardComponent } from './components/user-detail/wallet-banks-card/wallet-banks-card.component';
import { WalletTopupCardComponent } from './components/user-detail/wallet-topup-card/wallet-topup-card.component';
import { PageLockedAccountsComponent } from './pages/wallet/page-locked-accounts/page-locked-accounts.component';
import {UidService} from './services/uid.service';
import { IdScoreCardComponent } from './components/user-detail/id-score-card/id-score-card.component';
import { WalletFailureEventsTableComponent } from './components/wallet-failure-events-table/wallet-failure-events-table.component';
import {BillingService} from './services/billing.service';
import {PlatformBillingService} from './services/platform-billing.service';
import { PageBillersComponent } from './pages/billing/page-billers/page-billers.component';
import { BankAccountFormComponent } from './components/bank-account-form/bank-account-form.component';
import { WalletCardFormComponent } from './components/wallet-card-form/wallet-card-form.component';
import { FormBillerComponent } from './components/form-biller/form-biller.component';
import { CopyTextComponent } from './components/copy-text/copy-text.component';
import { PageBspsComponent } from './pages/billing/page-bsps/page-bsps.component';
import { MomentModule } from 'ngx-moment';
import {MenubarModule} from 'primeng/menubar';
import { PageMerchantsComponent } from './pages/payments/page-merchants/page-merchants.component';
import { FormMerchantComponent } from './components/form-merchant/form-merchant.component';
import {CVaultService} from './services/cvault.service';
import { PageUnspentBalancesComponent } from './pages/offers/page-unspent-balances/page-unspent-balances.component';
import { PageCreatedCardsComponent } from './pages/hapi/page-created-cards/page-created-cards.component';
import { PageOpenCardsComponent } from './pages/hapi/page-open-cards/page-open-cards.component';
import { PageOrganizationsComponent } from './pages/enterprise/page-organizations/page-organizations.component';
import {InputNumberModule} from 'primeng/inputnumber';
import { FormOrganizationComponent } from './components/form-organization/form-organization.component';
import { PageValetBlacklistComponent } from './pages/wallet/page-valet-blacklist/page-valet-blacklist.component';
import { PageMobileBlacklistComponent } from './pages/wallet/page-mobile-blacklist/page-mobile-blacklist.component';
import { PageInstrumentBlacklistComponent } from './pages/payments/page-instrument-blacklist/page-instrument-blacklist.component';
import {PageMerchantsOffersComponent} from './pages/offers/page-merchants/page-merchants-offers.component';
import {FormOffersMerchantComponent} from './components/offers/form-merchant/form-offers-merchant.component';
import { ShowErrorsComponent } from './components/show-errors/show-errors.component';
import { FormCampaignComponent } from './components/offers/form-campaign/form-campaign.component';
import { PageCampaignsComponent } from './pages/offers/page-campaigns/page-campaigns.component';
import { PageCampaignFormComponent } from './pages/offers/page-campaign-form/page-campaign-form.component';
import {ChipModule} from 'primeng/chip';
import {BadgeModule} from 'primeng/badge';
import {EditorModule} from 'primeng/editor';
import { PageOrganizationDetailComponent } from './pages/enterprise/page-organization-detail/page-organization-detail.component';
import { PageRewardProgramsComponent } from './pages/offers/page-reward-programs/page-reward-programs.component';
import { PageRewardProgramDetailComponent } from './pages/offers/page-reward-program-detail/page-reward-program-detail.component';
import { PageMerchantDetailComponent } from './pages/offers/page-merchant-detail/page-merchant-detail.component';
import { PageCreateRewardProgramComponent } from './pages/offers/page-create-reward-program/page-create-reward-program.component';
import { PageRewardCampaignDetailsComponent } from './pages/offers/page-reward-campaign-details/page-reward-campaign-details.component';
import { RewardProgramCardComponent } from './components/offers/reward-program-card/reward-program-card.component';
import { EditRichTextCardComponent } from './components/edit-rich-text-card/edit-rich-text-card.component';
import { RewardProgramLogTableComponent } from './components/reward-program-log-table/reward-program-log-table.component';
import { PageActivityDetailComponent } from './pages/offers/page-activity-detail/page-activity-detail.component';
import { FormBrandComponent } from './components/offers/form-brand/form-brand.component';
import { InplaceModule } from 'primeng/inplace';
import { EditInPlaceComponent } from './components/edit-in-place/edit-in-place.component';
import { OnboardingBrandComponent } from './pages/onboarding/onboarding-brand/onboarding-brand.component';
import { StepsModule } from 'primeng/steps';
import { OrganizationSummaryComponent } from './pages/onboarding/components/organization-summary/organization-summary.component';
import { FormAddressComponent } from './components/form/form-address/form-address.component';
import { FormBusinessInfoComponent } from './components/form-business-info/form-business-info.component';
import { OrganizationFinishComponent } from './pages/onboarding/components/organization-finish/organization-finish.component';
import { WalletPropertiesCardComponent } from './components/user-detail/wallet-properties-card/wallet-properties-card.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FicentiveTransactionTableComponent } from './components/ficentive-transaction-table/ficentive-transaction-table.component';
import { EditDropdownComponent } from './components/edit-dropdown/edit-dropdown.component';
import { ImageUploadButtonComponent } from './components/image-upload/image-upload-button.component';
import { ImageUploadDialogComponent } from './components/image-upload/image-upload-dialog/image-upload-dialog.component';
import { PageBrandsOffersComponent } from './pages/offers/page-brands/page-brands-offers.component';
import { RewardActivityTypeSummary } from './components/reward-activity-type-summary/reward-activity-type-summary.component';
import { BuildInfoService } from './services/build-info.service';
import { WalletVerifiedBanksCardComponent } from './components/user-detail/wallet-verified-banks-card/wallet-verified-banks-card.component';
import { RewardProgramTransactionTableComponent } from './components/reward-program-transaction-table/reward-program-transaction-table.component';
import { RewardProgramSubscribersTableComponent } from './components/reward-program-subscribers-table/reward-program-subscribers-table.component';
import { FormOffersMerchantPaymentComponent } from './components/form-offers-merchant-payment/form-offers-merchant-payment.component';
import { BillingSubscriptionsComponent } from './components/billing-subscriptions/billing-subscriptions.component'
import { ChatSettingsComponent } from './components/chat-support/chat-settings.component';
import { ContractConfigService } from './services/concr-config.service';
import { ErrorDisplayService } from './services/error-display.service';
import {
  PageTestAccountManagementComponent
} from './pages/wallet/page-test-account-management/page-test-account-management.component';
import { PageMerchantPaymentFormComponent } from './pages/offers/page-merchant-payment-form/page-merchant-payment-form.component';
import {EnterpriseUsersTableComponent} from './components/enterprise-users-table/enterprise-users-table.component';
import {PageBillingHistoryComponent} from './pages/enterprise/page-billing-history/page-billing-history.component';
import {
  PlatformBillingInvoicePreviewComponent
} from './components/platform-billing-invoice-preview/platform-billing-invoice-preview.component';
import {
  HapiMerchantTestTransactionsTableComponent
} from './components/hapi-merchant-test-transactions-table/hapi-merchant-test-transactions-table.component';
import {appRoutes} from './app-routing.module';
import {AffiliateService} from './services/affiliate.service';
import {PageAffiliatesComponent} from './pages/affiliate/page-affiliates/page-affiliates.component';
import {ValueDisplayComponent} from './components/value-display/value-display.component';
import {FileUploadDialogComponent} from './components/file-upload-dialog/file-upload-dialog.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {
  ClosedLoopTransactionsTableComponent
} from "./components/closedloop-transactions-table/closed-loop-transactions-table.component";
import {
  PageSearchClosedLoopTransactionsComponent
} from "./pages/hapi/page-search-closed-loop-transactions/page-search-closed-loop-transactions.component";

const extraOptions: ExtraOptions = {
    useHash: true,
    relativeLinkResolution: 'legacy'
    // initialNavigation: false,
    // Redirect issues with Auth0? check out this link, answer by kphay-11
    // https://community.auth0.com/t/angular-9-application-with-auth0-keeps-on-redirecting-after-successful-login-in-firefox/41394/7
};

/* Note about routes
  For searching/listing, use plural form of word, ie 'user/list'
  For viewing a specific instance, use singular form <slash> id, ie 'user/:uuid'
  For creating a new instance, use singular form, ie 'user'
  Modifying can either be the same as new instance with info populated, or can be built into the detail page of a single instance
*/

@NgModule({
  declarations: [
    AppComponent,
    ActivityFeedComponent,
    AddressBasicComponent,
    BadgeComponent,
    FeedItemComponent,
    FormCreateUserComponent,
    FormKycComponent,
    FormRewardCampaignComponent,
    LoanDetailComponent,
    LoanFeedComponent,
    LoanFunnelComponent,
    LoanListComponent,
    MerchantComponent,
    MerchantDetailComponent,
    NavbarComponent,
    OfferListComponent,
    PageActivitiesComponent,
    PageCreateRewardTransactionComponent,
    PageHeaderComponent,
    PageLoanApplicationComponent,
    PageLoginComponent,
    PagePaymentsComponent,
    PageReportsComponent,
    PageRewardsComponent,
    PageSearchTransactionsComponent,
    PageUserDetailComponent,
    PageMerchantsOffersComponent,
    PhoneComponent,
    SearchComponent,
    SherlockListComponent,
    SherlockDetailComponent,
    StCardViewComponent,
    StCardViewRowComponent,
    SummaryComponent,
    TransactionDetailComponent,
    PhonePipe,
    SSNPipe,
    PageSearchOffersUsersComponent,
    ConsumerCardComponent,
    KycCardComponent,
    PageConsumerSearchComponent,
    WalletOvcardCardComponent,
    AddressesCardComponent,
    ActivitiesTableComponent,
    FundsCardComponent,
    PageSearchKycComponent,
    OffersUserCardComponent,
    PageDeploymentsComponent,
    PageFirmwareComponent,
    WalletMobilesCardComponent,
    WalletValetsCardComponent,
    WalletTokensCardComponent,
    WalletEventsTableComponent,
    TransactionsTableComponent,
    TokenizedTransactionsTableComponent,
    FormFirmwareComponent,
    TransactionsCofTableComponent,
    PageCofTransactionsComponent,
    PageWalletBinsComponent,
    FormBinComponent,
    PageValetWhitelistComponent,
    TopupsTableComponent,
    FormBulkBinComponent,
    WalletCardsCardComponent,
    PagedCardComponent,
    WalletBanksCardComponent,
    WalletTopupCardComponent,
    PageLockedAccountsComponent,
    IdScoreCardComponent,
    WalletFailureEventsTableComponent,
    PageBillersComponent,
    BankAccountFormComponent,
    WalletCardFormComponent,
    FormBillerComponent,
    CopyTextComponent,
    PageBspsComponent,
    PageMerchantsComponent,
    FormMerchantComponent,
    PageUnspentBalancesComponent,
    PageCreatedCardsComponent,
    PageOpenCardsComponent,
    PageOrganizationsComponent,
    FormOrganizationComponent,
    PageValetBlacklistComponent,
    PageMobileBlacklistComponent,
    PageInstrumentBlacklistComponent,
    FormOffersMerchantComponent,
    ShowErrorsComponent,
    FormCampaignComponent,
    PageCampaignsComponent,
    PageCampaignFormComponent,
    PageOrganizationDetailComponent,
    PageRewardProgramsComponent,
    PageRewardProgramDetailComponent,
    PageMerchantDetailComponent,
    PageCreateRewardProgramComponent,
    PageRewardCampaignDetailsComponent,
    RewardProgramCardComponent,
    EditRichTextCardComponent,
    RewardProgramLogTableComponent,
    PageActivityDetailComponent,
    FormBrandComponent,
    EditInPlaceComponent,
    OnboardingBrandComponent,
    OrganizationSummaryComponent,
    FormAddressComponent,
    FormBusinessInfoComponent,
    OrganizationFinishComponent,
    WalletPropertiesCardComponent,
    FicentiveTransactionTableComponent,
    EditDropdownComponent,
    ImageUploadButtonComponent,
    ImageUploadDialogComponent,
    PageBrandsOffersComponent,
    RewardActivityTypeSummary,
    WalletVerifiedBanksCardComponent,
    RewardProgramTransactionTableComponent,
    RewardProgramSubscribersTableComponent,
    FormOffersMerchantPaymentComponent,
    BillingSubscriptionsComponent,
    ChatSettingsComponent,
    PageMerchantPaymentFormComponent,
    PageTestAccountManagementComponent,
    EnterpriseUsersTableComponent,
    PageBillingHistoryComponent,
    PlatformBillingInvoicePreviewComponent,
    HapiMerchantTestTransactionsTableComponent,
    PageAffiliatesComponent,
    ValueDisplayComponent,
    FileUploadDialogComponent,
    ClosedLoopTransactionsTableComponent,
    PageSearchClosedLoopTransactionsComponent,
  ],
  imports: [
    // AgmJsonViewerModule,
    AutoCompleteModule,
    BadgeModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    FormsModule,
    GMapModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImageCropperModule,
    InplaceModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    MenuModule,
    MenubarModule,
    MomentModule,
    MultiSelectModule,
    // NgbModule,
    NgxJsonViewerModule,
    PaginatorModule,
    PanelModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    RouterModule.forRoot(appRoutes, extraOptions),
    SelectButtonModule,
    SliderModule,
    StepsModule,
    TabViewModule,
    TableModule,
    InputMaskModule,
    ToastModule,
    ToggleButtonModule,
    TooltipModule,
    TriStateCheckboxModule,
    OverlayPanelModule,
    MegaMenuModule,
    GooglePlaceModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    AccessGuard,
    ActivityService,
    AuthService,
    BillingService,
    PlatformBillingService,
    CurrencyPipe,
    FundsService,
    MerchantService,
    MessageService,
    LoanService,
    OffersService,
    PaymentsService,
    AffiliateService,
    PhonePipe,
    ReportService,
    RewardsService,
    SherlockService,
    SSNPipe,
    UidService,
    ConfirmationService,
    HapiService,
    ConsumersApiService,
    WalletService,
    CVaultService,
    BuildInfoService,
    ContractConfigService,
    ErrorDisplayService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
