import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent, MenuItem, MessageService, SelectItem} from 'primeng/api';
import {EnterpriseApiService} from '../../../services/enterprise-api.service';
import {Organization} from '../../../domain/enterprise/organization';
import {ActiveInstrument} from '../../../domain/payments/active-instrument';
import {OffersService} from '../../../services/offers/offers-api.service';
import {PaymentsService} from '../../../services/payments.service';
import {MerchantVaultInstrumentData} from '../../../domain/enterprise/organization/merchant-vault-Instrument-data';
import {PageRequest} from '../../../domain/common/paging';
import {RewardProgram} from '../../../domain/offers/reward-program/reward-program';
import {OffersMerchant} from '../../../domain/offers/merchant/offers-merchant';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BusinessHours} from '../../../domain/enterprise/business-hours';
import {usTimezones} from '../../../domain/enterprise/us-timezones';
import {AuthService} from '../../../services/auth.service';
import {OrganizationWebsiteType} from '../../../domain/enterprise/organization/organization-website';
import {PlatformBillingService} from '../../../services/platform-billing.service';
import {Invoice, InvoiceStatus} from '../../../domain/platform-billing/invoices/invoice';
import {saveAs} from 'file-saver';
import {FilterField} from '../../../domain/common/search/filter-field';
import {BillingRecordStatus} from '../../../domain/payments/billing-batch-record';
import {OrganizationUpdateRequest} from '../../../domain/enterprise/organization/organization-update-request';
import {ContractConfigService} from '../../../services/concr-config.service';
import {OrganizationFeature} from '../../../domain/concr-config/organization-features-response';
import {OrganizationLink} from '../../../domain/enterprise/organization/organization-link';
import {OrganizationDocument} from '../../../domain/enterprise/organization/organization-document';
import {AffiliateService} from '../../../services/affiliate.service';
import {Affiliate} from '../../../domain/affiliate/affiliate';
import {Table} from 'primeng/table';
import {MarketplaceStore} from '../../../domain/enterprise/marketplaceStore';
import {WcWrapperService} from '../../../services/wc-wrapper.service';
import {CheckoutService} from '../../../services/checkout.service';
import {CheckoutRecord} from '../../../domain/checkout/checkout-records';
import {WooCallback} from '../../../domain/wc-wrapper/woo-callback';
import {AuthRoles} from '../../../domain/auth/auth-roles';

@Component({
  selector: 'app-page-organization-detail',
  templateUrl: './page-organization-detail.component.html',
  styleUrls: ['./page-organization-detail.component.css']
})
export class PageOrganizationDetailComponent implements OnInit {
  public readonly BillingRecordStatus = BillingRecordStatus;

  // Data
  orgId: string;
  organization: Organization;
  brands: Organization[];
  rewardProgram: RewardProgram = null;
  offersMerchant: OffersMerchant = null;
  paymentInstrumentsData: MerchantVaultInstrumentData = null;
  merchantInstrumentDataTimer = false;
  paymentInstrumentDialogTitle = 'The window will be closed in...';
  hasActiveCards = false;
  hasActiveBanks = false;
  activeInstrument: ActiveInstrument = null;
  isInstrumentDeleted = false;
  isAccountingOnlyRole = false;
  isAdminOrSupport = true;
  instrumentDisable = true;
  lastInvoice: Invoice
  deeplink: string;
  shortCode: string;

  // Visual
  loading = true;
  merchantLoading = true;
  billingLoading = false;
  showUpdateBusinessHoursForm = false;
  showPaymentInstrumentData = false;
  paymentInstrumentDataLoading = false;
  updateBusinessHoursForm: FormGroup;
  showAddPhoneNumberForm = false;
  newPhoneNumberForm: FormGroup;
  showAddWebsiteForm = false;
  newWebsiteForm: FormGroup;
  showAddImageForm = false;
  showSelectedInvoice = false;
  downloadingInvoice = false;
  featuresLoading = false;

  tabs: SelectItem[];

  daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  timezones = [];
  mondayClosure: boolean;
  tuesdayClosure: boolean;
  wednesdayClosure: boolean;
  thursdayClosure: boolean;
  fridayClosure: boolean;
  saturdayClosure: boolean;
  sundayClosure: boolean;
  websiteTypes = Object.values(OrganizationWebsiteType).filter(type => typeof type === 'string');

  organizationFeatures: OrganizationFeature[] = [];
  bizChat = false;

  /*
  * Legal Document Upload
  * */
  showLegalDocumentUpload = false;
  organizationDocuments: OrganizationDocument[] = [];
  loadingDocuments = false;

  /*
  * Magic Links (and shortCodes)
  * */
  organizationLinks: OrganizationLink[] = [];
  newOrganizationLinkForm: FormGroup;
  showAddOrganizationLinkForm = false;
  loadingLinks = false;

  affiliate: Affiliate;

  /*
  * Marketplace
  * */
  @ViewChild('platformsDt') platformsDatatable: Table;
  platforms: MarketplaceStore[];
  platformsCols = [
    {field: 'platform', header: 'Platform'},
    {field: 'alias', header: 'Store Alias'},
    {field: 'url', header: 'Store URL'},
    {field: 'actions', header: ''},
  ];
  platformsTotalRecords: number;
  platformsLoading = false;
  @ViewChild('transactionsDt') transactionsDatatable: Table;
  transactions: CheckoutRecord[];
  transactionsCols = [
    {field: 'orderId', header: 'Invoice Number'},
    {field: 'grandTotal', header: 'Amount'},
    {field: 'description', header: 'Description'},
    {field: 'created', header: 'Date'},
    {field: 'status', header: 'Status'},
  ];
  transactionsTotalRecords: number;
  transactionsLoading = false;
  transactionEventState: LazyLoadEvent;

  newPlatformForm: FormGroup;
  showAddPlatformForm = false;
  newPlatformOptions = [
    {label: 'WooCommerce', value: 'woo'}
  ];
  editPlatformStoreForm: FormGroup;
  showEditPlatformStoreForm = false;

  selectedTransaction: CheckoutRecord;
  showSelectedTransaction = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private enterpriseApi: EnterpriseApiService,
              private offersApi: OffersService,
              private paymentsService: PaymentsService,
              private confirmationService: ConfirmationService,
              private errorDisplayService: ErrorDisplayService,
              private messageService: MessageService,
              private fb: FormBuilder,
              private authService: AuthService,
              private platformBillingService: PlatformBillingService,
              private contractConfigService: ContractConfigService,
              private affiliateService: AffiliateService,
              private wcWrapperService: WcWrapperService,
              private checkoutService: CheckoutService) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.brands = [];
      this.organization = null;
      this.rewardProgram = null;
      this.offersMerchant = null
      this.loadOrganization(params['uuid']);
    });

    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.isAdminOrSupport = result);
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT, AuthRoles.ACCOUNTING]).subscribe(result => this.instrumentDisable = !result);
    this.authService.hasRole([AuthRoles.ACCOUNTING]).subscribe(result => this.isAccountingOnlyRole = result);

    this.newPhoneNumberForm = this.fb.group({
      phoneNumbers: this.fb.array([])
    });

    this.newWebsiteForm = this.fb.group({
      websites: this.fb.array([])
    });

    this.newOrganizationLinkForm = this.fb.group({
      links: this.fb.array([])
    });

    this.newPlatformForm = this.fb.group({
      platform: ['', [Validators.required]],
      url: ['', [Validators.required]],
      keyId: ['', [Validators.required]],
      consumerKey: ['', [Validators.required]],
      consumerSecret: ['', [Validators.required]],
    })

    this.editPlatformStoreForm = this.fb.group({
      platform: [''],
      alias: ['', [Validators.required]],
      url: [''],
      storeId: ['']
    })
  }

  initializeForm() {
    const mondayHours = this.organization?.businessHours?.operationHours?.find(day => day.weekDay === 'monday');
    const tuesdayHours = this.organization?.businessHours?.operationHours?.find(day => day.weekDay === 'tuesday');
    const wednesdayHours = this.organization?.businessHours?.operationHours?.find(day => day.weekDay === 'wednesday');
    const thursdayHours = this.organization?.businessHours?.operationHours?.find(day => day.weekDay === 'thursday');
    const fridayHours = this.organization?.businessHours?.operationHours?.find(day => day.weekDay === 'friday');
    const saturdayHours = this.organization?.businessHours?.operationHours?.find(day => day.weekDay === 'saturday');
    const sundayHours = this.organization?.businessHours?.operationHours?.find(day => day.weekDay === 'sunday');

    this.updateBusinessHoursForm = this.fb.group({
      timezone: [this.organization?.businessHours?.timeZone],
      mondayOpensAt: [mondayHours?.opensAt],
      mondayClosesAt: [mondayHours?.closesAt],
      tuesdayOpensAt: [tuesdayHours?.opensAt],
      tuesdayClosesAt: [tuesdayHours?.closesAt],
      wednesdayOpensAt: [wednesdayHours?.opensAt],
      wednesdayClosesAt: [wednesdayHours?.closesAt],
      thursdayOpensAt: [thursdayHours?.opensAt],
      thursdayClosesAt: [thursdayHours?.closesAt],
      fridayOpensAt: [fridayHours?.opensAt],
      fridayClosesAt: [fridayHours?.closesAt],
      saturdayOpensAt: [saturdayHours?.opensAt],
      saturdayClosesAt: [saturdayHours?.closesAt],
      sundayOpensAt: [sundayHours?.opensAt],
      sundayClosesAt: [sundayHours?.closesAt],
    });

    this.mondayClosure = !this.updateBusinessHoursForm?.get('mondayOpensAt')?.value && !this.updateBusinessHoursForm?.get('mondayClosesAt')?.value;
    this.tuesdayClosure = !this.updateBusinessHoursForm?.get('tuesdayOpensAt')?.value && !this.updateBusinessHoursForm?.get('tuesdayClosesAt')?.value;
    this.wednesdayClosure = !this.updateBusinessHoursForm?.get('wednesdayOpensAt')?.value && !this.updateBusinessHoursForm?.get('wednesdayClosesAt')?.value;
    this.thursdayClosure = !this.updateBusinessHoursForm?.get('thursdayOpensAt')?.value && !this.updateBusinessHoursForm?.get('thursdayClosesAt')?.value;
    this.fridayClosure = !this.updateBusinessHoursForm?.get('fridayOpensAt')?.value && !this.updateBusinessHoursForm?.get('fridayClosesAt')?.value;
    this.saturdayClosure = !this.updateBusinessHoursForm?.get('saturdayOpensAt')?.value && !this.updateBusinessHoursForm?.get('saturdayClosesAt')?.value;
    this.sundayClosure = !this.updateBusinessHoursForm?.get('sundayOpensAt')?.value && !this.updateBusinessHoursForm?.get('sundayClosesAt')?.value;
  }

  // Initial API Calls
  loadOrganization(uuid: string) {
    this.orgId = uuid;
    this.loading = true;
    this.enterpriseApi.getOrganizationById(uuid).subscribe(result => {
      console.log('Organization fetched.');
      this.organization = result;
      if (this.organization.isParent) {
        this.enterpriseApi.getOrganizationBrands(uuid).subscribe(result => {
          this.brands = result;
        }, error => {
          this.brands = []
        });
        this.getAffiliate(uuid);
      } else {
        this.enterpriseApi.getOrganizationBusinessHours(uuid).subscribe(result => {
          this.organization.businessHours = result;
          this.initializeForm();
        }, error => {
          this.initializeForm();
          this.errorDisplayService.displayErrorResponse('Get Organization Business Hours', error);
        });
        this.getLastInvoice();
        this.getActiveMerchantCards(uuid);
        this.getActiveMerchantBanks(uuid);
        this.getFeatures();
        this.getOrganizationsLinks(uuid);
        this.getDocuments(uuid);
      }
      this.loading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Organization', error);
      this.loading = false;
    });

    this.merchantLoading = true;
    this.offersApi.getMerchants(new PageRequest(0, 10), { entBrandId: uuid }).subscribe(results => {
      this.merchantLoading = false;
      if (results?.content?.length > 0) {
        this.offersMerchant = results.content[0];
        this.rewardProgram = results.content[0].rewardProgram;
      }
    }, error => {
      this.merchantLoading = false;
    });

    this.getPlatforms();
  }

  // Modifying API calls
  handleDisplayInListChange(val) {
    this.enterpriseApi.updateOrganization(this.orgId, {displayInList: val.checked}).subscribe(result => {
      // console.log('display in list changed');
      this.organization = result;
    });
  }

  updateProperty(request: OrganizationUpdateRequest) {
    this.loading = true;
    this.enterpriseApi.updateOrganization(this.orgId, request).subscribe(result => {
      this.organization = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Updating Property', error);
    }, () => {
      this.loading = false;
    })
  }

  updateDescription(description) {
    this.updateProperty({description});
  }

  updateAddress1(address1) {
    this.updateProperty({address1});
  }

  updateAddress2(address2) {
    this.updateProperty({address2});
  }

  updateAffiliatedBy(affiliatedBy) {
    this.updateProperty({affiliatedBy});
  }

  updateCity(city) {
    this.updateProperty({city});
  }

  updateState(state) {
    this.updateProperty({state});
  }

  updateZip(zip) {
    this.updateProperty({zip});
  }

  updateInvitationUrl(invitationURL) {
    this.updateProperty({invitationURL});
  }

  deleteOrganization() {
    this.enterpriseApi.deleteOrg(this.orgId).subscribe(result => {
      console.log('deleted org');
      this.router.navigateByUrl(`enterprise/organizations`);
    }, err => {
      console.error(err);
    })
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this organization?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteOrganization();
      },
      reject: () => {
        console.log('Cancelled user deletion')
      }
    });
  }

  createRewardProgram() {
    if (this.offersMerchant == null) {
      this.offersApi.createBrand({
        name: this.organization.displayName,
        giftOption: false,
        verified: true,
        entOrgId: this.organization.parentId,
        entBrandId: this.orgId
      }).subscribe(offersBrand => {
        this.offersMerchant = offersBrand;
        this.createRewardProgramFromMerchant();
      });
    } else {
      this.createRewardProgramFromMerchant();
    }
  }

  private createRewardProgramFromMerchant() {
    const expirationPeriod = 365;
    const description = `<ul><li>Rewards can be earned and spent at any ${this.offersMerchant.name} locations.</li><li>If not spent within ${expirationPeriod} days, rewards will expire.</li></ul>`;
    const request = {brandId: this.offersMerchant.uuid, title: `${this.offersMerchant.name} Loop`, expirationPeriod, description};
    this.offersApi.createRewardProgram(request).subscribe((rp) => {
      this.rewardProgram = rp;
    });
  }

  addNewBrand() {
    this.router.navigateByUrl(`enterprise/onboarding?parent=${this.organization.id}`);
  }

  getBusinessHoursForDay(dayOfWeek) {
    const hours = this.organization?.businessHours?.operationHours.find(day => day.weekDay === dayOfWeek);
    if (!hours) {
return 'Closed';
}
    return `${hours.opensAt} - ${hours.closesAt}`;
  }

  newPaymentInstrument() {
    this.router.navigateByUrl(`/enterprise/organization/${this.orgId}/paymentform`);
  }

  getActiveMerchantCards(entBrandId: string) {
    this.paymentsService.getAdminMerchantsInstrumentsCards(entBrandId).subscribe(result => {
      const { content } = result;
      const obj = content.find( el => el.status === 'ACTIVE' )

      if (obj) {
        obj.paymentCard.id = obj.id
        this.activeInstrument = obj.paymentCard
        this.hasActiveCards = true;
      }
    })
  }

  getActiveMerchantBanks(entBrandId: string) {
    this.paymentsService.getAdminMerchantsInstrumentsBanks(entBrandId).subscribe(result => {
      const { content } = result;
      const obj = content.find( el => el.status === 'ACTIVE' )

      if (obj) {
        obj.bankAccount.id = obj.id
        this.activeInstrument = obj.bankAccount
        this.hasActiveBanks = true;
      }
    })
  }

  deleteActiveInstrument() {
    this.paymentsService.deleteMerchantInstrument(this.activeInstrument['id']).subscribe( result => {
      this.isInstrumentDeleted = true;
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Payment instrument was deleted.'});
    });
  }

  getMerchantInstrumentData() {
    this.showPaymentInstrumentData = true;
    this.paymentInstrumentDataLoading = true;
    const activeInstrumentId = this.activeInstrument['id']

    this.paymentsService.createMerchantInstrumentDataRequest(this.orgId, activeInstrumentId).subscribe(result => {
      const url = result?.headers?.get('location');
      this.paymentInstrumentDataLoading = false;

      if (url) {
        this.paymentsService.getMerchantInstrumentDataByRequest(url, this.orgId, activeInstrumentId).subscribe(data => {
          this.paymentInstrumentsData = data;
          this.merchantInstrumentDataTimer = true;
          this.setCountdown(9)

          setTimeout(item => {
            this.merchantInstrumentDataTimer = false;
            this.showPaymentInstrumentData = false;
          }, 10000);
        }, error => {
          this.merchantInstrumentDataTimer = false;
          this.showPaymentInstrumentData = false;
          this.errorDisplayService.displayErrorResponse('Getting merchant instrument details', error);
        })
      }
    }, error => {
      this.showPaymentInstrumentData = false;
      this.paymentInstrumentDataLoading = false;
      this.errorDisplayService.displayErrorResponse('Creating merchabt instrument request', error);
    })
  }

  setCountdown(n: number) {
    const countdownId = setInterval( item => {
      this.paymentInstrumentDialogTitle = `The window will be closed in ${n} seconds`
      n--

      if (n < 0 || !this.showPaymentInstrumentData) {
        clearInterval(countdownId)
        this.paymentInstrumentDialogTitle = `The window will be closed in...`
      }
    }, 1000)
  }

  updateBusinessHours() {
    const data: BusinessHours = {
      timeZone: this.updateBusinessHoursForm.get('timezone').value,
      operationHours: []
    };
    if (this.updateBusinessHoursForm.get('mondayOpensAt').value) {
data.operationHours.push({
        weekDay: 'monday',
        opensAt: this.updateBusinessHoursForm.get('mondayOpensAt').value,
        closesAt: this.updateBusinessHoursForm.get('mondayClosesAt').value
      });
}
    if (this.updateBusinessHoursForm.get('tuesdayOpensAt').value) {
data.operationHours.push({
        weekDay: 'tuesday',
        opensAt: this.updateBusinessHoursForm.get('tuesdayOpensAt').value,
        closesAt: this.updateBusinessHoursForm.get('tuesdayClosesAt').value
      });
}
    if (this.updateBusinessHoursForm.get('wednesdayOpensAt').value) {
data.operationHours.push({
        weekDay: 'wednesday',
        opensAt: this.updateBusinessHoursForm.get('wednesdayOpensAt').value,
        closesAt: this.updateBusinessHoursForm.get('wednesdayClosesAt').value
      });
}
    if (this.updateBusinessHoursForm.get('thursdayOpensAt').value) {
data.operationHours.push({
        weekDay: 'thursday',
        opensAt: this.updateBusinessHoursForm.get('thursdayOpensAt').value,
        closesAt: this.updateBusinessHoursForm.get('thursdayClosesAt').value
      });
}
    if (this.updateBusinessHoursForm.get('fridayOpensAt').value) {
data.operationHours.push({
        weekDay: 'friday',
        opensAt: this.updateBusinessHoursForm.get('fridayOpensAt').value,
        closesAt: this.updateBusinessHoursForm.get('fridayClosesAt').value
      });
}
    if (this.updateBusinessHoursForm.get('saturdayOpensAt').value) {
data.operationHours.push({
        weekDay: 'saturday',
        opensAt: this.updateBusinessHoursForm.get('saturdayOpensAt').value,
        closesAt: this.updateBusinessHoursForm.get('saturdayClosesAt').value
      });
}
    if (this.updateBusinessHoursForm.get('sundayOpensAt').value) {
data.operationHours.push({
        weekDay: 'sunday',
        opensAt: this.updateBusinessHoursForm.get('sundayOpensAt').value,
        closesAt: this.updateBusinessHoursForm.get('sundayClosesAt').value
      });
}
    this.enterpriseApi.updateOrganizationBusinessHours(this.orgId, data).subscribe(result => {
      this.organization.businessHours = result;
      this.showUpdateBusinessHoursForm = false;
      this.initializeForm();
    }, error => {
      this.errorDisplayService.displayErrorResponse('Update Business Hours', error);
    });
  }

  validateUpdateBusinessHoursForm() {
    if (!this.updateBusinessHoursForm.get('timezone').value) {
return true;
}

    // both open and close times must exist if there is no closure on that day
    if (!this.mondayClosure && (!this.updateBusinessHoursForm.get('mondayOpensAt').value || !this.updateBusinessHoursForm.get('mondayClosesAt').value)) {
return true
}
    if (!this.tuesdayClosure && (!this.updateBusinessHoursForm.get('tuesdayOpensAt').value || !this.updateBusinessHoursForm.get('tuesdayClosesAt').value)) {
return true
}
    if (!this.wednesdayClosure && (!this.updateBusinessHoursForm.get('wednesdayOpensAt').value || !this.updateBusinessHoursForm.get('wednesdayClosesAt').value)) {
return true
}
    if (!this.thursdayClosure && (!this.updateBusinessHoursForm.get('thursdayOpensAt').value || !this.updateBusinessHoursForm.get('thursdayClosesAt').value)) {
return true
}
    if (!this.fridayClosure && (!this.updateBusinessHoursForm.get('fridayOpensAt').value || !this.updateBusinessHoursForm.get('fridayClosesAt').value)) {
return true
}
    if (!this.saturdayClosure && (!this.updateBusinessHoursForm.get('saturdayOpensAt').value || !this.updateBusinessHoursForm.get('saturdayClosesAt').value)) {
return true
}
    if (!this.sundayClosure && (!this.updateBusinessHoursForm.get('sundayOpensAt').value || !this.updateBusinessHoursForm.get('sundayClosesAt').value)) {
return true
}

    // close value must be greater than open value
    if (Number(this.updateBusinessHoursForm?.get('mondayOpensAt')?.value?.replace(':', '')) > Number(this.updateBusinessHoursForm?.get('mondayClosesAt')?.value?.replace(':', ''))) {
return true
}
    if (Number(this.updateBusinessHoursForm?.get('tuesdayOpensAt')?.value?.replace(':', '')) > Number(this.updateBusinessHoursForm?.get('tuesdayClosesAt')?.value?.replace(':', ''))) {
return true
}
    if (Number(this.updateBusinessHoursForm?.get('wednesdayOpensAt')?.value?.replace(':', '')) > Number(this.updateBusinessHoursForm?.get('wednesdayClosesAt')?.value?.replace(':', ''))) {
return true
}
    if (Number(this.updateBusinessHoursForm?.get('thursdayOpensAt')?.value?.replace(':', '')) > Number(this.updateBusinessHoursForm?.get('thursdayClosesAt')?.value?.replace(':', ''))) {
return true
}
    if (Number(this.updateBusinessHoursForm?.get('fridayOpensAt')?.value?.replace(':', '')) > Number(this.updateBusinessHoursForm?.get('fridayClosesAt')?.value?.replace(':', ''))) {
return true
}
    if (Number(this.updateBusinessHoursForm?.get('saturdayOpensAt')?.value?.replace(':', '')) > Number(this.updateBusinessHoursForm?.get('saturdayClosesAt')?.value?.replace(':', ''))) {
return true
}
    if (Number(this.updateBusinessHoursForm?.get('sundayOpensAt')?.value?.replace(':', '')) > Number(this.updateBusinessHoursForm?.get('sundayClosesAt')?.value?.replace(':', ''))) {
return true
}
  }

  searchTimezones(event) {
    const search = event.query;
    this.timezones = usTimezones.filter(timezone => event ? this.caseInsensitiveCompare(timezone, search) : true)
  }

  caseInsensitiveCompare(str1, str2) {
    return str1?.toLowerCase().includes(str2?.toLowerCase())
  }

  handleDayClosure(event, day) {
    switch(day) {
      case 'monday':
        this.mondayClosure = !this.mondayClosure;
        if (event.checked === false) {
this.updateBusinessHoursForm.patchValue({
            mondayOpensAt: null,
            mondayClosesAt: null
          });
}
        break;
      case 'tuesday':
        this.tuesdayClosure = !this.tuesdayClosure;
        if (event.checked === false) {
this.updateBusinessHoursForm.patchValue({
            tuesdayOpensAt: null,
            tuesdayClosesAt: null
          });
}
        break;
      case 'wednesday':
        this.wednesdayClosure = !this.wednesdayClosure;
        if (event.checked === false) {
this.updateBusinessHoursForm.patchValue({
            wednesdayOpensAt: null,
            wednesdayClosesAt: null
          });
}
        break;
      case 'thursday':
        this.thursdayClosure = !this.thursdayClosure;
        if (event.checked === false) {
this.updateBusinessHoursForm.patchValue({
            thursdayOpensAt: null,
            thursdayClosesAt: null
          });
}
        break;
      case 'friday':
        this.fridayClosure = !this.fridayClosure;
        if (event.checked === false) {
this.updateBusinessHoursForm.patchValue({
            fridayOpensAt: null,
            fridayClosesAt: null
          });
}
        break;
      case 'saturday':
        this.saturdayClosure = !this.saturdayClosure;
        if (event.checked === false) {
this.updateBusinessHoursForm.patchValue({
            saturdayOpensAt: null,
            saturdayClosesAt: null
          });
}
        break;
      case 'sunday':
        this.sundayClosure = !this.sundayClosure;
        if (event.checked === false) {
this.updateBusinessHoursForm.patchValue({
            sundayOpensAt: null,
            sundayClosesAt: null
          });
}
        break;
    }
  }

  /*
  * Phone Numbers
  * */

  showNewPhoneNumberForm() {
    this.newPhoneNumberForm.setControl('phoneNumbers', this.fb.array([]));
    this.organization.phoneNumbers?.forEach(phoneNumber => this.addFormPhoneNumber(phoneNumber.description, phoneNumber.number));
    this.newPhoneNumberForm.markAsPristine();
    this.showAddPhoneNumberForm = true;
  }

  get phoneNumbers() {
    return this.newPhoneNumberForm?.controls['phoneNumbers'] as FormArray;
  }

  addPhoneNumberFormGroup(description: string = '', number: string = ''): FormGroup {
    return this.fb.group({
      description: [description, [Validators.required]],
      number: [number, [Validators.required]]
    })
  }

  addFormPhoneNumber(description: string = '', number: string = '') {
    this.phoneNumbers.push(this.addPhoneNumberFormGroup(description, number));
  }

  deletePhoneNumber(phoneNumberIndex: number) {
    this.phoneNumbers.removeAt(phoneNumberIndex)
    this.newPhoneNumberForm.markAsDirty();
  }

  savePhoneNumber() {
    this.showAddPhoneNumberForm = false;
    this.loading = true;
    this.enterpriseApi.updateOrganizationPhoneNumber(this.orgId, this.newPhoneNumberForm.get('phoneNumbers').value).subscribe(result => {
      this.organization.phoneNumbers = result;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Manage Phone Numbers', error);
    })
  }

  /*
  * Websites
  * */

  showNewWebsiteForm() {
    this.newWebsiteForm.setControl('websites', this.fb.array([]));
    this.organization.websites?.forEach(website => this.addFormWebsite(website.type, website.url, website.description));
    this.newWebsiteForm.markAsPristine();
    this.showAddWebsiteForm = true;
  }

  get websites() {
    return this.newWebsiteForm?.controls['websites'] as FormArray;
  }

  addWebsiteFormGroup(type: string = this.websiteTypes[0], url: string = '', description: string = ''): FormGroup {
    return this.fb.group({
      type: [type, [Validators.required]],
      url: [url, [Validators.required]],
      description: [description, [Validators.required]]
    })
  }

  addFormWebsite(type: string = this.websiteTypes[0], url: string = '', description: string = '') {
    this.websites.push(this.addWebsiteFormGroup(type, url, description));
  }

  deleteWebsite(websiteIndex: number) {
    this.websites.removeAt(websiteIndex)
    this.newWebsiteForm.markAsDirty();
  }

  saveWebsite() {
    this.showAddWebsiteForm = false;
    this.loading = true;
    this.enterpriseApi.updateOrganizationWebsites(this.orgId, this.newWebsiteForm.get('websites').value).subscribe(result => {
      this.organization.websites = result;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Manage Website', error);
    })
  }

  /*
  * Images
  * */

  getImages() {
    this.loading = true;
    this.enterpriseApi.getOrgImages(this.orgId).subscribe(result => {
      this.organization.images = result;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Getting Organization Images', error);
    })
  }

  deleteImage(imageIndex: number) {
    const imageName = this.organization.images[imageIndex].fileName;
    this.enterpriseApi.deleteImage(this.orgId, imageName).subscribe(result => {
      this.getImages();
    }, error => {
      this.errorDisplayService.displayErrorResponse('Delete Image', error);
    })
  }

  handleImageUploaded() {
    this.showAddImageForm = false;
  }

  /*
  * Billing
  * */

  getStatusColor(status) {
    return InvoiceStatus.getStatusColor(status);
  }

  formatStatus(status) {
    return InvoiceStatus.formatStatusName(status);
  }

  goToBillingHistory() {
    this.router.navigateByUrl(`/billing/billinghistory?brandId=${this.orgId}`);
  }

  getLastInvoice() {
    this.billingLoading = true;
    const pageRequest = new PageRequest(0, 1);
    const filterFields: FilterField[] = [];
    filterFields.push({parameter: 'sort', value: 'createdAtDesc'});
    filterFields.push({parameter: 'brandId', value: this.orgId});
    this.platformBillingService.getInvoices(pageRequest, filterFields).subscribe(result => {
      this.lastInvoice = result[0];

      if (this.lastInvoice) {
        const filters: FilterField[] = [];
        filters.push({ parameter: 'brandId', value: this.orgId});
        filters.push({ parameter: 'ovInvoiceId', value: this.lastInvoice.id});
        this.paymentsService.getBrandBillingBatchRecords(new PageRequest(0, 1), filters).subscribe(result => {
          this.lastInvoice.paymentRecord = result.content[0];
          const nextPayment = new Date();
          nextPayment.setDate(10);
          nextPayment.setMonth(nextPayment.getMonth() + 1);
          this.lastInvoice.nextPaymentDate = nextPayment.getTime();
        }, error => {
          this.errorDisplayService.displayErrorResponse('Get last invoice payment record', error);
        });
      }
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get most recent bills', error);
    }).add(() => {
      this.billingLoading = false;
    })
  }

  downloadInvoice = (invoice: Invoice) => {
    this.downloadingInvoice = true;
    this.platformBillingService.downloadInvoice(invoice.fileURL).subscribe(blob => {
        saveAs(blob, `ovloop-invoice-${invoice.number}.pdf`)
      }, error => {
        this.errorDisplayService.displayErrorResponse('Download Invoice', error);
      }).add(() => {
        this.downloadingInvoice = false;
    });
  }

  /*
  * Contract Config
  * */

  mapFeatures(features: OrganizationFeature[]) {
    features.forEach(feature => {
      if (feature.key === 'bizChat') {
        this.bizChat = feature.value === 'true';
      }
      // add other features here
    });
  }

  getFeatures() {
    this.featuresLoading = true;
    this.contractConfigService.getOrganizationFeatures(this.orgId).subscribe(result => {
      this.organizationFeatures = result;
      this.mapFeatures(result);
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Contract Features', error);
    }).add(() => {
      this.featuresLoading = false;
    })
  }

  updateFeatures = (request: OrganizationFeature[]) => {
    this.featuresLoading = true;
    this.contractConfigService.updateOrganizationFeatures(this.orgId, request).subscribe(result => {
      this.organizationFeatures = result;
      this.mapFeatures(result);
    }, error => {
      this.errorDisplayService.displayErrorResponse('Update Contract Features', error);
    }).add(() => {
      this.featuresLoading = false;
    })
  }

  updateBizChat(event) {
    const updatedFeatures = this.organizationFeatures;
    const index = this.organizationFeatures.findIndex(feature => feature.key === 'bizChat');
    if (index >= 0) {
      updatedFeatures[index].value = event.checked ? 'true' : 'false';
    } else {
      updatedFeatures.push({
        key: 'bizChat',
        value: event.checked ? 'true' : 'false'
      });
    }
    this.updateFeatures(updatedFeatures);
  }

  /*
  * Links (Shortcodes and their links)
  * */

  getOrganizationsLinks(uuid: string) {
    this.loadingLinks = true;
    this.enterpriseApi.getOrganizationLinks(uuid).subscribe(links => {
      this.organizationLinks = links;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Organization ShortCodes and their Links', error);
    }).add(() => {
      this.loadingLinks = false;
    });
  }

  addFormLink(url: string = '', shortCode: string = '') {
    this.links.push(this.addLinksFormGroup(url, shortCode));
  }

  showNewOrganizationLinkForm() {
    this.newOrganizationLinkForm.setControl('links', this.fb.array([]));
    this.organizationLinks?.forEach(link => this.addFormLink(link.url, link.shortCode));
    this.newOrganizationLinkForm.markAsPristine();
    this.showAddOrganizationLinkForm = true;
  }

  get links() {
    return this.newOrganizationLinkForm?.controls['links'] as FormArray;
  }

  addLinksFormGroup(url: string = '', shortCode: string = ''): FormGroup {
    return this.fb.group({
      url: [url, [Validators.required]],
      shortCode: [shortCode, [Validators.required]]
    })
  }

  deleteLink(linkIndex: number) {
    this.links.removeAt(linkIndex)
    this.newOrganizationLinkForm.markAsDirty();
  }

  saveLink() {
    this.showAddOrganizationLinkForm = false;
    this.loading = true;
    this.enterpriseApi.updateOrganizationLinks(this.orgId, this.newOrganizationLinkForm.get('links').value).subscribe(result => {
      this.organizationLinks = result;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Manage Links', error);
    })
  }

  /*
  * Documents
  * */

  getDocuments(uuid: string) {
    this.loadingDocuments = true;
    this.enterpriseApi.getOrganizationDocuments(uuid).subscribe(documents => {
      this.organizationDocuments = documents;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Legal Documents', error);
    }).add(() => {
      this.loadingDocuments = false;
    });
  }

  showNewDocumentForm() {
    this.showLegalDocumentUpload = true;
  }

  handleDocumentUpload() {
    this.showLegalDocumentUpload = false;
    this.getDocuments(this.orgId);
  }

  getAffiliate(uuid: string) {
    this.affiliateService.getAffiliates([{parameter: 'affiliatedId', value: uuid}]).subscribe(result => {
      this.affiliate = result[0];
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Affiliate', error);
    })
  }

  /*
  * Marketplace
  * */

  getPlatformName(platform: string) {
    return this.newPlatformOptions.find(p => p.value = platform).label;
  }

  getPlatforms() {
    this.platformsLoading = true;
    const stores: MarketplaceStore[] = [];

    // get woo stores
    this.wcWrapperService.getStores(this.orgId).subscribe(results => {
      this.platformsTotalRecords = results.length;
      const wooStores = results.map(wooStore => ({
        id: wooStore.id,
        platform: 'woo',
        alias: wooStore.name,
        url: wooStore.storeURL,
      }));
      stores.push(...wooStores);
    }, err => {
      this.errorDisplayService.displayErrorResponse('Get WooCommerce Stores', err);
    });

    this.platforms = stores;
    this.platformsLoading = false;
  }

  handleAddPlatformFormSubmit() {
    if (this.newPlatformForm.invalid) {
      return;
    }
    this.loading = true;
    if (this.newPlatformForm.controls.platform.value === 'woo') {
      const req: WooCallback = {
        user_id: this.orgId,
        key_id: this.newPlatformForm.controls.keyId.value,
        consumer_key: this.newPlatformForm.controls.consumerKey.value,
        consumer_secret: this.newPlatformForm.controls.consumerSecret.value,
        key_permissions: 'read_write'
      };
      this.wcWrapperService.createStoreCredential(this.newPlatformForm.controls.url.value, req).subscribe(result => {
        this.getPlatforms();
        this.showAddPlatformForm = false;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.errorDisplayService.displayErrorResponse('Add WooCommerce Platform', err);
      });
    }
    // else if() {} other platforms...
  }

  getPlatformsOptions(store: MarketplaceStore) {
    const menuOptions: MenuItem[] = [];

    const editStore: MenuItem = {
      label: 'Edit Store',
      disabled: !this.isAdminOrSupport,
      command: () => {
        this.editPlatformStoreForm.patchValue({
          platform: this.getPlatformName(store.platform),
          alias: store.alias,
          url: store.url,
          storeId: store.id
        })
        this.showEditPlatformStoreForm = true;
      }
    }
    const deleteStore: MenuItem = {
      label: 'Delete Store',
      disabled: !this.isAdminOrSupport,
      command: () => {
        this.confirmationService.confirm({
          message: `Are you sure you want to delete the store '${store.alias}'?`,
          header: 'Delete Confirmation',
          icon: 'fa fa-trash',
          accept: () => {
            if (store.platform === 'woo') {
              this.wcWrapperService.deleteStoreCredential(this.orgId, store.id).subscribe(result => {
                this.getPlatforms();
              }, err => {
                this.loading = false;
                this.errorDisplayService.displayErrorResponse(`Delete ${store.alias} Store`, err);
              });
            }
          },
          reject: () => {}
        });
      }
    }

    //base options
    menuOptions.push(editStore);
    menuOptions.push(deleteStore);

    return menuOptions;
  }

  clearNewPlatform() {
    this.newPlatformForm.reset();
  }

  clearEditPlatformStore() {
    this.editPlatformStoreForm.reset();
  }

  handleEditPlatformStoreFormSubmit() {
    if (this.editPlatformStoreForm.invalid) {
      return;
    }
    this.loading = true;
    if (this.editPlatformStoreForm.controls.platform.value === this.getPlatformName('woo')) {
      const storeId = this.editPlatformStoreForm.controls.storeId.value;
      const req = {
        name: this.editPlatformStoreForm.controls.alias.value
      }
      this.wcWrapperService.editStore(this.orgId, storeId, req).subscribe(result => {
        this.loading = false;
        this.showEditPlatformStoreForm = false;
        this.getPlatforms();
      }, err => {
        this.showEditPlatformStoreForm = false;
        this.loading = false;
        this.errorDisplayService.displayErrorResponse('Edit WooCommerce Store', err);
      });
    } else {
      this.loading = false;
    }
  }

  getTransactions(pageRequest: PageRequest) {
    this.transactionsLoading = true;
    this.checkoutService.getCheckoutRecords(pageRequest, this.orgId).subscribe(result => {
      this.transactions = result.content;
      this.transactionsTotalRecords = result.totalElements;
      this.transactionsLoading = false;
    }, err => {
      this.transactionsLoading = false;
      this.errorDisplayService.displayErrorResponse('Get Transactions', err);
    });
  }

  loadTransactionsDataLazy(event: LazyLoadEvent) {
    this.transactionEventState = event;
    const pageRequest: PageRequest = {page: event.first / event.rows, size: event.rows};
    this.getTransactions(pageRequest);
  }

  getTransactionOptions(transaction: CheckoutRecord) {
    const menuOptions: MenuItem[] = [];

    const viewTransaction: MenuItem = {
      label: 'View Transaction',
      command: () => {
        this.selectedTransaction = transaction;
        this.showSelectedTransaction = true;
      }
    }

    //base options
    menuOptions.push(viewTransaction);

    return menuOptions;
  }

  refreshTransactions() {
    this.getTransactions({page: 0, size: this.transactionEventState.rows})
  }
}
